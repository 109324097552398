import { gql } from '@apollo/client'
import { createApolloClient } from '@app/lib/graphql/createApolloClient'
import Login, { LoginProps } from '@app/pageComponents/Login/Login'

export async function getServerSideProps(
	context: any
): Promise<{ props: LoginProps }> {
	const host =
		process.env.NODE_ENV === 'development' && false
			? 'joejuice.agitron.app'
			: context.req.headers.host
	const customer =
		process.env.NODE_ENV === 'development' && false
			? 'joejuice'
			: host.split('.')[0]

	const client = createApolloClient(host, context)

	try {
		const { data } = await client.query({
			query: gql`
				query loginPageSettings($customer: String!) {
					loginPageSettings(customer: $customer) {
						customer
						loginLogo
						logo
						primaryColor
						textColor
					}
				}
			`,
			variables: { customer },
		})

		return {
			props: {
				customer: data?.loginPageSettings?.customer || '',
				logo: data?.loginPageSettings?.logo || '',
				loginLogo: data?.loginPageSettings?.loginLogo || '',
				primaryColor: data?.loginPageSettings?.primaryColor || '',
				textColor: data?.loginPageSettings?.textColor || '',
			},
		}
	} catch (e) {
		return {
			props: {},
		}
	}
}

const LoginPage = ({
	customer,
	logo,
	loginLogo,
	primaryColor,
	textColor,
}: LoginProps) => {
	return (
		<Login
			customer={customer}
			logo={logo}
			loginLogo={loginLogo}
			primaryColor={primaryColor}
			textColor={textColor}
		/>
	)
}

LoginPage.layout = 'loginPage'

export default LoginPage
